<template>
  <profile-loader v-show="loading"></profile-loader>
  <cropper :src="originImage"
           :name="name"
           :position="position"
           v-if="showCropper"
           @cropped="croppingImage"
           @close="closeCropper">
  </cropper>
  <div v-show="!loading" class="home-container">
      <navbar title="Edit Profile"></navbar>
      <div class="body-page">
          <div class="body-section mt-60">
              
          </div>
      </div>

      <div class="image-canvas">
          <div class="overlay-photo">
            <div style="position: relative;width: 40px;height: 40px;overflow: hidden;top:0;left:0;">
              <input class="file-input" type="file" name="profilePicture" @input="handleSelects"
                 accept="image/png, image/jpg, image/jpeg"/>
            </div>
            <i class="fa-solid fa-pen"></i>
          </div>

          <div class="image-holder">
              <img class="picture" id="profile-photo" alt="">
          </div>
      </div>

      <div style="width: 90%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  margin: 0 auto;
                  margin-top: 0px;
                  padding: 0">
        <span style="font-size: 19pt;font-weight: 600;">{{ name }}</span>
        <!-- <span style="margin-top: -5px;">IT Development</span> -->
      </div>
      <canvas style="display: none;" id="resize-image"></canvas>

      <div class="profile-content">
              <div class="profile-navigation">
                    <div class="items" 
                         :class="{'items-active': selectedNavigation === 1}"
                         @click="selectedNavigation = 1">
                          Contact
                    </div>
                    <div class="items"
                         :class="{'items-active': selectedNavigation === 2}"
                         @click="selectedNavigation = 2">
                          Biograph
                    </div>
                    <div class="items"
                         :class="{'items-active': selectedNavigation === 3}"
                         @click="selectedNavigation = 3">
                          Information
                    </div>
              </div> 

              <div v-if="selectedNavigation === 1" class="input-container">
                  <div class="input-group">
                    <label for="email">Email</label>
                    <input name="email" type="email" class="input" v-model="email">
                  </div>
                  <div class="input-group">
                    <label for="email">Phone</label>
                    <input name="phone" type="text" class="input" v-model="phone">
                  </div>
              </div>

              <div v-if="selectedNavigation === 2" class="input-container">
                <div class="input-group">
                  <label for="tl">Tempat Lahir</label>
                  <input name="tl" type="text" class="input readonly" :value="birth_place" readonly>
                </div>
                <div class="input-group">
                  <label for="tgl">Tanggal Lahir</label>
                  <input name="tgl" type="text" class="input readonly" :value="birth_date" readonly>
                </div>
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;">
                    <div class="input-group" style="width:48%">
                      <label for="jenkel">Jenis Kelamin</label>
                      <input name="jenkel" type="text" class="input readonly" :value="gender" readonly>
                    </div>
                    <div class="input-group" style="width:48%">
                      <label for="goldar">Golongan Darah</label>
                      <input name="goldar" type="text" class="input readonly" :value="blood" readonly>
                    </div>
                </div>
                <div class="input-group">
                  <label for="alamt">Alamat</label>
                  <textarea name="alamat" id="" cols="10" rows="5" class="input-textarea readonly" v-model="address"></textarea>
                </div>
              </div>

              <div v-if="selectedNavigation === 3" class="input-container">
                <div class="input-group">
                  <label for="id">ID Karyawan</label>
                  <input name="id" type="text" class="input readonly" :value="nik" readonly>
                </div>
                <div class="input-group">
                  <label for="perusahaan">Perusahaan</label>
                  <input name="perusahaan" type="text" class="input readonly" :value="comp_kd" readonly>
                </div>
                <div class="input-group">
                  <label for="divisi">Divisi</label>
                  <input name="divisi" type="text" class="input readonly" :value="divisi_kd" readonly>
                </div>
                <div class="input-group">
                  <label for="sub-divisi">Sub Divisi</label>
                  <input name="sub-divisi" type="text" class="input readonly" :value="subdivisi_kd" readonly>
                </div>
                <div class="input-group">
                  <label for="department">Department</label>
                  <input name="department" type="text" class="input readonly" :value="dept_kd" readonly>
                </div>
              </div>

              <div class="divider"></div>
              <div class="dissclaimer">
                  <h3 class="title">Dissclaimer</h3>
                  <p>Semua informasi mengenai data karyawan yang terdaftar dalam perusahaan tertentu, tidak membatasi area tugas ataupun penempatan scara aktual.</p>
                  <p>Dengan menggunakan dan membaca informasi dalam aplikasi ini, Anda dengan secara langsung maupun tidak langsung setuju dengan disclaimer yang telah dicantumkan.</p>
              </div>
              <div class="divider"></div>

              <button class="save-btn" @click="submit">Save</button>
      </div>

  </div>

  <alert v-if="showAlert" 
        :message="message" 
        :status="status" 
        :type="types"
        @clicked="onClosedAlert">
  </alert>
</template>
<script>
import axios from 'axios';
import Navbar from '@/components/Navbar.vue';
import ProfileLoader from '@/components/Loader/ProfileLoader.vue';
import Alert from '@/components/Alert.vue';
import Cropper from "@/components/Cropper.vue";

export default {
    name: 'ProfileView',
    components: { 
      Navbar, 
      ProfileLoader, 
      Alert, 
      Cropper,
      // 'my-upload': myUpload,
    },
    data(){
        return {
          images: null,
          image: [],
          filename: null,
          files: null,
          gImage: false,
          loading: true,
          form: {
            image: null,
            image_name: '',
          },
          email: null,
          phone: null,
          nik: null,
          name: null,
          birth_place: null,
          birth_date: null,
          gender: null,
          blood: null,
          address: null,
          comp_kd: null,
          divisi_kd: null,
          subdivisi_kd: null,
          dept_kd: null,
          showAlert: false,
          message: null,
          status: false,
          types: null,
          croppedImage: null,
          originImage: null,
          showCropper: false,
          selectedNavigation: 1,
        }
    },
    created(){
        setTimeout(() => {this.loading = false}, 1000);
        this.getProfile()
        this.getProfileImage()
    },
    methods: {
      onClosedAlert(value){this.showAlert = value},
      closeCropper(value){this.showCropper = value},
      async getProfileImage(){
        await fetch(this.uri + '/images/profile/' + this.$store.getters.GET_AUTH_INFO.photo, {
          method: 'GET',
          headers: { 
              'Authorization' : this.$store.getters.GET_AUTH_TOKEN
          }
        }).then((res) => {
              return res.blob()
        }).then((blob) => {
              let url = URL.createObjectURL(blob)
              document.getElementById('profile-photo').src = url
        }).catch((e) => {
            if(e.response.status == 401){
                this.$store.dispatch("REFRESH")
                .then(() => {
                    window.location.reload()
                })
                .catch(() => {
                    this.$router.push({name: 'login'})
                })
            } else if(e.response.status == 406) {
                this.$store.dispatch("LOGOUT")
                .then(() => {
                    this.$router.push({ path : '/'});
                }).catch(() => {
                    this.$router.push({ path : '/'});
                });
            }
        })
      },
      getProfile(){
        this.comp_kd = this.$store.getters.GET_AUTH_INFO.company
        this.divisi_kd = this.$store.getters.GET_AUTH_INFO.division
        this.subdivisi_kd = this.$store.getters.GET_AUTH_INFO.subdivision
        this.dept_kd = this.$store.getters.GET_AUTH_INFO.department
        this.blood = this.$store.getters.GET_AUTH_INFO.blood
        this.gender = this.$store.getters.GET_AUTH_INFO.gender
        this.address = this.$store.getters.GET_AUTH_INFO.address
        this.birth_date = new Date(this.$store.getters.GET_AUTH_INFO.birth_date).toLocaleDateString()
        this.birth_place = this.$store.getters.GET_AUTH_INFO.birth_place
        this.name = this.$store.getters.GET_AUTH_INFO.name
        this.nik = this.$store.getters.GET_AUTH_INFO.nik
        this.phone = this.$store.getters.GET_AUTH_INFO.phone
        this.email = this.$store.getters.GET_AUTH_INFO.email
      },
      croppingImage(value){
        if(value){
          this.showCropper = false
          document.getElementById('profile-photo').src = value
        }
      },
      setCropping(){
        const pictures = document.getElementById("resize-image");
        const getImg = pictures.toDataURL("image/jpg");
        var byteString = atob(getImg.split(',')[1]);
        var mimeString = getImg.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const fName = 'Attendance' + (Math.floor(Date.now() / 1000)) + '.jpg';
        var myBlob = new Blob([ab], {type: mimeString});
        const myFile = new File([myBlob], fName, {
            type: myBlob.type,
        });

        var resblob = myFile.slice(0, myFile.size, myFile.type);
        if(this.image){
          this.showCropper = true
          this. originImage = URL.createObjectURL(resblob)
        }
      },
      handleSelects(e){
        var maximumSize = 1 * 1024 * 2048;
        if(e.target.files[0]) this.image = e.target.files[0];
        if(e.target.files[0] && e.target.files[0].size > maximumSize){
            this.images = null
            this.message = "Attachment size too large, maximum 2 Mb!"
            this.status = false
            this.showAlert = true
            return;
        }

        var blob = this.image.slice(0, this.image.size, this.image.type); 
        var canvas = document.getElementById('resize-image');
        var ctx = canvas.getContext("2d");
        var resizeWidth, resizeHeight;
        var img = new Image()
        img.onload = () => {
            var width = parseInt(img.width)
            var height = parseInt(img.height)
            if(width < 1000) { 
              resizeWidth = width
              resizeHeight = height
            } else if(width > 1000 && width < 2048) {
              resizeWidth = width / 2
              resizeHeight = height / 2
            } else if(width > 2048 && width < 3840) {
              resizeWidth = width / 4
              resizeHeight = height / 4
            } else if(width > 3840 && width < 4000) {
              resizeWidth = width / 8
              resizeHeight = height / 8
            } else if(width > 4000) {
              this.images = null
              this.message = "Image dimension exceed the limit"
              this.status = false
              this.showAlert = true
              return;
            }

            var sWidth, sHeight;
            if(resizeWidth % 2 !== 0) sWidth = String(resizeWidth).split(".")[0]
            else sWidth = resizeWidth

            if(resizeHeight % 2 !== 0) sHeight = String(resizeHeight).split(".")[0]
            else sHeight = resizeHeight

            canvas.width = sWidth
            canvas.height = sHeight
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        };
        img.src = URL.createObjectURL(blob)
        setTimeout(() => {this.setCropping(blob)}, 500);
      },
      submit(){
        let myEmail, myPhone, myAddress;
        if(!this.email){
            myEmail = this.$store.getters.GET_AUTH_INFO.email
        } else { myEmail = this.email }

        if(!this.phone){  
            myPhone = this.$store.getters.GET_AUTH_INFO.phone
        } else { myPhone = this.phone }

        if(!this.address){
            myAddress = this.$store.getters.GET_AUTH_INFO.address
        } else { myAddress = this.address }

        const formData = new FormData();
        const pictures = document.getElementById("profile-photo");
        const fileName = 'Profile-' + String(this.$store.getters.GET_AUTH_INFO.name).split(" ")[0] + '-' 
                          + String(Math.floor(Math.random() * 1839763215)) + String(Date.now()) 
                          + '.jpg';

        fetch(pictures.src)
        .then(res => res.blob())
        .then(blob => {
          this.files = new File([blob], fileName, blob)          
          formData.append('nik', this.nik);
          formData.append('email', myEmail);
          formData.append('phone', myPhone);
          formData.append('address', myAddress);
          if(this.files) formData.append('profile', this.files);

          axios.post('/profile', formData, {
              headers: {
                'Authorization': this.$store.getters.GET_AUTH_TOKEN
            }
          }).then((res) => {
              this.$store.commit('SET_AUTH_INFO', res.data.data);
              this.message = res.data.message
              this.status = true
              this.types = 'home'
              this.showAlert = true
          }).catch((err) => {
              console.log(err)
              if(err.response.status == 401){
                  this.$store.dispatch("REFRESH")
                  .then(() => {
                      window.location.reload()
                  })
                  .catch(() => {
                      this.$router.push({name: 'login'})
                  })
              } else if(err.response.status == 406) {
                  this.$store.dispatch("LOGOUT")
                  .then(() => {
                      this.$router.push({ path : '/'});
                  }).catch(() => {
                      this.$router.push({ path : '/'});
                  });
              }
          })
        })
      },
    }
}
</script>

<style>

</style>
