<template>
    <div class="reset-loader">
        <div class="login-form">
            <div class="shimmer-wrapper" style="width: 100%;">
                <div style="display: flex; flex-direction: columns;gap: 10px" class="mt-10">
                    <div style="width: 25px;height: 25px;" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
                    <div style="margin-top: 11px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
                </div>
                
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate mt-30 h-40"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate mt-20 h-40"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate mt-30"></div>
                <div style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 15px;">
                    <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                    <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10 ml-30"></div>
                    <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                    <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10 ml-30"></div>
                    <div class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate mt-10"></div>
                </div>
                
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate mt-30 h-50"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResetLoader',
}
</script>

<style>
.reset-loader{
    position: relative;
    top: 0;
    left: 0;
    width: 100svw;
    height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 1000;
    overflow: hidden;
}

.reset-loader .login-form{
    position: relative;
    left: -2%;
    width: 86%;
    max-width: 400px;
    height: 390px;
    border-radius: 5px;
    border: 1.5px solid #e0e0e0;
    margin: 0 auto;
    margin-top: 40px;
    padding: 15px;
    background: #fff;
    box-shadow: 0 3px 5px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
</style>