<template>
  <clockin-loader v-show="loading"></clockin-loader>
  <div v-show="!loading" class="home-container">
      <navbar-vue></navbar-vue>
      <div class="body-page">
            <div class="map-container" v-show="!showCamera">
              <!-- <l-map ref="map" v-model:zoom="zoom" :center="[currPos.lat, currPos.lng]" :options="{zoomControl: false, attributionControl: false}">
                <l-tile-layer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" layer-type="base" name="OpenStreetMap"></l-tile-layer>
                <l-circle-marker :lat-lng="[currPos.lat, currPos.lng]" :radius="7" 
                :color="'red'" :stroke="false" :fill="true" :fillColor="'#d40000'" :fillOpacity="1" />
                <l-circle-marker :lat-lng="[currPos.lat, currPos.lng]" :radius="14" 
                :color="'red'" :stroke="false" :fill="true" :fillColor="'#d40000'" :fillOpacity="0.4" />
                <l-circle :lat-lng="[rad.lat, rad.lng]" :radius="50" :stroke="false" 
                :color="'#0084d1'" :fill="true" :fillColor="'#0084d1'" :fillOpacity="0.5"
                v-for="rad in location" :key="rad.id"></l-circle>
              </l-map> -->
            </div>
            <div class="clock-detail">
                <div class="clock-input">
                      <div class="handle"></div>

                      <div class="location-name">
                          <i id="loc-icon" class="fa-solid fa-location-dot"></i>
                          <span :class="{'text-danger' : selectedlocation === 'Out Of Ranges'}">Location : {{ selectedlocation }}</span>
                      </div>

                      <div class="photo-section">
                          <div>
                              <img v-show="!is_captured" class="default-image" src="/assets/images/user/default.png" alt="">
                              <div v-show="is_captured" class="camera-input" @click="init">
                                  <canvas class="image-holder" id="selectedpicture"></canvas>
                                  <div class="image-text">
                                    <i style="font-size: 19pt;" class="fa-solid fa-camera" :class="{'text-white' : images}"></i>
                                    <span :class="{'text-white' : images}">{{ selectimages }}</span>
                                  </div>
                              </div>
                          </div>

                          <div class="user-detail">
                              <span class="user-name">{{ this.$store.getters.GET_AUTH_INFO.name }}</span>
                              <span class="user-date">{{ myDate }}</span>
                              <span class="user-time">{{ myTime }}</span>
                          </div>
                      </div>

                      <span class="label-input" style="margin-top: 15px;">Keterangan</span>
                      <textarea name="" id="" cols="30" rows="5" class="textarea-input" v-model="descriptions"></textarea>

                      <span class="footer-text">
                          ESS STI
                          <span class="version">v1.0</span>
                      </span>
                </div>
                <div class="button-container">
                      <button v-if="!showCamera" @click="submit" class="clock-btn">
                        <span v-if="!is_processing">Live Attendance - {{ title }}</span>

                        <div v-if="is_processing" class="spinner center">
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                            <div class="spinner-blade"></div>
                        </div>
                      </button>
                </div>
            </div>
      </div>
  </div>

  <div v-if="showCamera" class="camera-container">
      <div 
        class="camera-switch" 
        @click="switchCam"
        v-if="isCameraReady">
        <i class="fa-solid fa-camera-rotate"></i>
      </div>

      <video class="camera" autoplay muted playsinline=""></video>
      
      <div 
        class="camera-close" 
        @click="closeCam" 
        v-if="isCameraReady">
        <i class="fa-solid fa-x"></i>
      </div>

      <button 
        @click="capture" 
        id="capture" 
        class="camera-snap" 
        v-if="isCameraReady">
          <i class="fa-solid fa-camera"></i>
      </button>
  </div>

  <alert v-if="showAlert" 
          :message="message" 
          :status="status" 
          :type="types"
          @clicked="onClosedAlert">
  </alert>
</template>

<script>
import { computed } from 'vue'
import { useGeolocation } from '@/useGeolocation'
import "leaflet/dist/leaflet.css"
// import { LMap, LTileLayer, LCircle, LCircleMarker } from "@vue-leaflet/vue-leaflet";
import L from 'leaflet'
import axios from 'axios'
import NavbarVue from '@/components/Navbar.vue'
import ClockinLoader from '@/components/Loader/ClockinLoader.vue'
import Alert from '@/components/Alert.vue'
import { DateTime } from 'luxon'

export default {
    name: 'ClockinView',
    components: {
      // LMap, LTileLayer, LCircle, LCircleMarker,
      NavbarVue,
      ClockinLoader,
      Alert,
    },
    data(){
      return {
              title: '',
              showAlert: false,
              message: null,
              status: false,
              types: null,
              loading: true,
              times: '00:00',
              myDate: '',
              myTime: '-- : -- : --',
              zoom: 16,
              crntpos: null,
              inRanges: false,
              showCamera: false,
              showQuestions: false,
              is_answer: false,
              is_wrong: false,
              is_processing: false,
              is_captured: false,
              selectedChoice: '',
              wrongCounter: 0,
              questionCounter: 0,
              images: false,
              image: null,
              selectimages: 'Tambah Foto',
              currentlat: 0,
              currentlng: 0,
              clocking: null,
              descriptions: null,
              divisi: null,
              subdivisi: null,
              dept: null,
              lok_kd: null,
              locCounter: 0,
              listDevices: [],
              counterDevices: 0,
              firstAttemp: false,
              device_label: 'asdasda',
              device_id: 'AA',
              device_kind: 'asdas',
              device_group: '0',
              selectedlocation: '',
              currentLocation: '',
              selectedlocation_kd: 'Not in ranges',
              getLocation: [],
              locations: [],
              schedule: [],
              working: [],
              working_time: '00:00:00',
              is_working: false,
              question: [],
              facingmodes: 'user',
              isCameraReady: false,
          }
      },
      setup(){
        const { coords } = useGeolocation();
        const currPos = computed(() => ({
          lat: coords.value.latitude,
          lng: coords.value.longitude,
        }));
        return{ currPos }
      },
      beforeRouteLeave(){this.removeMaps()},
      created(){
          if(document.getElementById("map")) this.removeMaps();
          this.createMaps();
          this.getAttendanceLocation();
          setInterval(() => {
            this.createDate()
          }, 1000);
          
          if(this.$route.name === 'clockin') {
            this.clocking = true
            this.title = 'Clock In'
          } else {
            this.clocking = false
            this.title = 'Clock Out'
          }
      },
      methods: {
          onClosedAlert(value){this.showAlert = value},
          removeMaps(){document.getElementById("map").remove()},
          createMaps(){
            const newMap = document.createElement("div");
            newMap.setAttribute("id", "map");
            const parentDiv = document.getElementById("app");
            document.body.insertBefore(newMap, parentDiv);
          },
          getAttendanceLocation(){
            axios.get('/employee_attend/' + this.$route.params.types, {
                  headers: {
                    'Authorization' : this.$store.getters.GET_AUTH_TOKEN
                  }
            }).then((res) => {
                this.getLocation = res.data.data.location
                this.getLocation.forEach((data) => {
                    this.locations.push(data)
                })

                this.isInRange()
            }).catch((err) => {
                if(err.response.status == 401){
                    this.$store.dispatch("REFRESH")
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(() => {
                        this.$router.push({name: 'login'})
                    })
                }
            })
          },
          switchCam(){ 
              if(this.facingmodes === 'user') this.facingmodes = 'environment'
              else this.facingmodes = 'user'

              if(!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) alert("enumerateDevices is not supported.");
              if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
                    navigator.mediaDevices.getUserMedia({
                      audio: false, 
                      video: {
                        facingMode: {
                          ideal: this.facingmodes
                        },
                        width: 1080, 
                        height: 1080,
                      },
                      focusMode: "continuous",
                    }).then(stream => {
                        const videoPlayer = document.querySelector("video");
                        videoPlayer.srcObject = stream;
                        videoPlayer.removeAttribute('controls');                
                        // videoPlayer.play();
                        if(this.facingmodes == 'environment') {
                          videoPlayer.style.transform = 'scaleX(1)'
                          videoPlayer.style['-webkit-transform'] = 'scaleX(1)';
                        } else {
                          videoPlayer.style.transform = 'scaleX(-1)'
                          videoPlayer.style['-webkit-transform'] = 'scaleX(-1)';
                        }
                    })
                } else {alert("mediaDevices not supported!")}

              // if(!this.firstAttemp && this.listDevices.length > 0){
              //   this.counterDevices = 1;
              //   this.firstAttemp = true;
              // }

              // if(this.counterDevices > this.listDevices.length) this.counterDevices = 0;
              // if(this.listDevices[this.counterDevices] !== undefined){
              //   const device_id = this.listDevices[this.counterDevices].device_id;
              //   const video = document.querySelector('video');
              //   const mediaStream = video.srcObject;
              //   const tracks = mediaStream.getVideoTracks();
              //   tracks.forEach(track => track.stop());
                
              //   if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
              //       navigator.mediaDevices.getUserMedia({
              //         audio: false, 
              //         video: {
              //           deviceId: device_id,
              //           width: 1080, 
              //           height: 1080,
              //         },
              //         focusMode: "continuous",
              //       }).then(stream => {
              //           const videoPlayer = document.querySelector("video");
              //           videoPlayer.srcObject = stream;
              //           videoPlayer.removeAttribute('controls');                
              //           // videoPlayer.play();
              //           if(this.counterDevices > 0) {
              //             videoPlayer.style.scale = "(-1, 1)"
              //           } else {
              //             videoPlayer.style.scale = "(-1, 1)"
              //           }
              //       })
              //   } else {alert("mediaDevices not supported!")}
              // }
              // this.counterDevices++;
          },
          init(){
            this.isCameraReady = false;
            this.listDevices = [];
            navigator.mediaDevices.enumerateDevices().then((devices) => {
              devices.forEach((device) => {
                  const obj = {
                      device_label: '',
                      device_id: '',
                  }

                  if(device.deviceId && device.kind === "videoinput"){
                      obj.device_label = device.label;
                      obj.device_id = device.deviceId;
                      this.listDevices.push(obj);
                  }
              });
            }).catch(function (e) {
                this.message = e.name + ": " + e.message
                this.status = false
                this.showAlert = true
                return;
            });
            
            document.getElementById("map").setAttribute("hidden", "");
            this.showCamera = true;
            if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
                navigator.mediaDevices.getUserMedia({
                  audio: false, 
                  video: {
                    width: 1080, 
                    height: 1080,
                  }, 
                  facingMode: "user",
                  focusMode: "continuous",
                }).then(stream => {
                    const videoPlayer = document.querySelector("video");
                    videoPlayer.srcObject = stream;    
                    // videoPlayer.play();
                    videoPlayer.removeAttribute('controls'); 
                    this.isCameraReady = true;               
                })
            } else {alert("mediaDevices not supported!")}
          },
          closeCam(){
            const video = document.querySelector('video');
            const mediaStream = video.srcObject;
            const tracks = mediaStream.getVideoTracks();
            tracks.forEach(track => track.stop());
            this.firstAttemp = false;
            this.showCamera = false;
            document.getElementById("map").removeAttribute('hidden');
            this.isCameraReady = false;
          },
          capture(){
            // let ratio = (window.innerHeight > window.innerWidth) ? 16 / 9 : 9 / 16;
            const pictures = document.getElementById("selectedpicture");
            pictures.width = 500
            pictures.height = 300
            const ctx = pictures.getContext("2d");
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage(document.querySelector("video"), 0, 0, pictures.width, pictures.height);
            // ctx.scale(-1, 1);
            this.images = true;
            this.selectimages = 'Ubah Foto';
            const video = document.querySelector('video');
            const mediaStream = video.srcObject;
            const tracks = mediaStream.getVideoTracks();
            tracks.forEach(track => track.stop());
            this.is_captured = true;
            this.time();
            this.firstAttemp = false;
            this.showCamera = false;
            document.getElementById("map").removeAttribute('hidden');            
          },
          async isInRange(){
            if ("geolocation" in navigator) {
              navigator.geolocation.getCurrentPosition(
                async(position) => {
                  this.currentlat = position.coords.latitude;
                  this.currentlng = position.coords.longitude;                  
                  var map = L.map('map', {
                      center: [this.currentlat, this.currentlng],
                      zoom: 17,
                      zoomControl: false,
                  });

                  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                      maxZoom: 19, 
                      attribution: '© OpenStreetMap'
                  }).addTo(map);

                  let profileIcon;
                  const filename = this.$store.getters.GET_AUTH_INFO.photo;
                  await fetch(this.uri + "/images/profile/" + filename, {
                    mode: "cors",
                    method: "GET",
                    headers: {
                      Authorization: this.$store.getters.GET_AUTH_TOKEN,
                      AccessToken: this.$store.getters.GET_AUTH_INFO.access_token,
                    },
                })
                  .then((res) => {
                    return res.blob();
                  })
                  .then((blob) => {
                    profileIcon = URL.createObjectURL(blob);
                    const style = document.createElement('style');
                    style.innerHTML = `
                        .leaflet-circular-icon {
                            border-radius: 50%;
                            overflow: hidden;
                            animation: image-border 1s ease infinite;
                        }
                        .leaflet-circular-icon img {
                            border-radius: 50%;
                        }
                        @keyframes image-border{
                          0% {border: 2.5px solid #F7B787}
                          50% {border: 2.5px solid #EE7214}
                          100% {border: 2.5px solid #F7B787}
                        }    
                    `;
                    document.head.appendChild(style);
                  });

                  var crntpos;
                  if(profileIcon){
                      var LeafIcon = L.Icon.extend({
                          options: {
                              iconSize:     [60, 60],
                              shadowSize:   [60, 60],
                              className: 'leaflet-circular-icon',
                          }
                      });

                      var pIcons = new LeafIcon({iconUrl: profileIcon});
                      crntpos = L.marker([parseFloat(this.currentlat), parseFloat(this.currentlng)], {icon: pIcons}).addTo(map)
                  } else {
                      crntpos = new L.circle([parseFloat(this.currentlat), parseFloat(this.currentlng)], { 
                          radius: 7, 
                          color: 'red',
                          stroke: false,
                          fill: true,
                          fillColor: '#b22a2e',
                          fillOpacity: 0.8,
                      }).addTo(map);

                      new L.circle([parseFloat(this.currentlat), parseFloat(this.currentlng)], { 
                          radius: 14,
                          color: 'red',
                          stroke: false,
                          fill: true,
                          fillColor: '#b22a2e',
                          fillOpacity: 0.4,
                      }).addTo(map);
                  }

                  this.locCounter = 0;
                  this.locations.forEach((el) => {
                      var radloc = new L.circle([el.latitude, el.longitude], {
                          radius: el.radius, 
                          color: '#008DDA',
                          stroke: true,
                          weight: 1,
                          fill: true,
                          fillColor: '#ACE2E1',
                          fillOpacity: 0.3,
                      }).addTo(map);

                      // new L.popup().setLatLng([el.latitude, el.longitude])
                      //       .setContent('<p>' + el.lok_nm + '.</p>')
                      //       .addTo(map);

                      // new L.tooltip().setLatLng([el.latitude, el.longitude]).setContent(el.lok_nm).addTo(map);

                      var cenloc = radloc.getLatLng();
                      if (cenloc.distanceTo(crntpos.getLatLng()) <= radloc.getRadius()) {
                          this.locCounter += 1
                          this.selectedlocation = el.lok_nm
                          this.selectedlocation_kd = el.lok_kd
                          this.divisi = el.divisi_kd
                          this.subdivisi = el.subdivisi_kd
                          this.dept = el.dept_kd
                      }
                  });
                  if(this.locCounter > 0){
                    document.getElementById('loc-icon').style.color = '#00BCD4'
                  } else {
                    document.getElementById('loc-icon').style.color = '#faad91'
                    fetch(`https://nominatim.openstreetmap.org/reverse?lat=${this.currentlat}&lon=${this.currentlng}&format=json`, {
                      headers: {
                          'User-Agent': 'ESS SARI TIRTA INDONESIA'
                      }
                    }).then(res => res.json())
                    .then(res => {
                        this.selectedlocation = res.display_name
                    })
                  }
                },
                (error) => {
                  alert(`Error getting location : ${error.message}`);
                  window.location.reload();
                },
                {maximumAge:10000, timeout:5000, enableHighAccuracy: true},
              );
              this.loading = false
            } else {alert("Geolocation is not supported in this browser.")}
          },
          checkAnswer(value){
            this.selectedChoice = value
            if(this.selectedChoice !== this.question.answer){
              this.wrongCounter++
            } else {
              this.showQuestions = false;
            }
          },
          submit(){
            if(this.is_processing) return;
            if(this.locCounter <= 0){
               this.message = 'Your location is out of radius'
               this.status = false
               this.showAlert = true
               return;
            }

            if(!this.is_captured){
                this.showCamera = true;
                this.init();
                return;
            }

            this.is_processing = true
            const pictures = document.getElementById("selectedpicture");
            const getImg = pictures.toDataURL("image/jpg");
            var byteString = atob(getImg.split(',')[1]);
            var mimeString = getImg.split(',')[0].split(':')[1].split(';')[0]
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            const fName = 'Attendance-' + String(this.$store.getters.GET_AUTH_INFO.name).split(" ")[0] + '-' + 
                           String(Math.floor(Math.random() * 1839763215)) + String(Date.now()) + '.jpg';
                           
            var myBlob = new Blob([ab], {type: mimeString});
            const myFile = new File([myBlob], fName, {
                type: myBlob.type,
            });

            const dates = DateTime.now().setZone('Asia/Jakarta')
            const currentDate = dates.toJSDate()
            const myTimes = dates.toISOTime()
            const currentTime = String(myTimes).split(".")[0]

            const formData = new FormData();
            formData.append('latitude', this.currentlat);
            formData.append('longitude', this.currentlng);
            formData.append('usernik', this.$store.getters.GET_AUTH_INFO.nik);            
            formData.append('photo', myFile);
            formData.append('description', this.descriptions);
            formData.append('clocking', this.clocking);
            formData.append('attendDate', currentDate);
            formData.append('attendTime', currentTime);
            formData.append('divisi_kd', this.divisi);
            formData.append('subdivisi_kd', this.subdivisi);
            formData.append('dept_kd', this.dept);
            formData.append('lok_kd', this.selectedlocation_kd);
            formData.append('lok_nm', this.selectedlocation);
            axios.post('/attendance', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization' : this.$store.getters.GET_AUTH_TOKEN
                }
            }).then((res) => {
                this.is_processing = false;
                this.message = res.data.message
                this.status = true
                this.types = 'live'
                this.showAlert = true
            }).catch((err) => {
                this.is_processing = false;
                if(err.response.status == 401){
                  this.$store.dispatch("REFRESH")
                  .then(() => {
                      window.location.reload()
                  })
                  .catch(() => {
                      this.$router.push({name: 'login'})
                  })
                } else if(err.response.status == 406) {
                    this.$store.dispatch("LOGOUT")
                    .then(() => {
                        this.$router.push({ path : '/'});
                    }).catch(() => {
                        this.$router.push({ path : '/'});
                    });
                }

                this.message = err.response.data.message
                this.status = false
                this.showAlert = true
            });
          },
          time() {
              var d = new Date();
              var s = d.getSeconds();
              var m = d.getMinutes();
              var h = d.getHours();
              this.myTime = ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2) + ":" + ("0" + s).substr(-2);
          },
          createDate(){
            var d = new Date();
            var day = d.getDate();
            var year = d.getFullYear();
            switch (new Date().getDay()) {
            case 0:
                this.day = "Sunday";
                break;
            case 1:
                this.day = "Monday";
                break;
            case 2:
                this.day = "Tuesday";
                break;
            case 3:
                this.day = "Wednesday";
                break;
            case 4:
                this.day = "Thursday";
                break;
            case 5:
                this.day = "Friday";
                break;
            case 6:
                this.day = "Saturday";
            }

            switch (new Date().getMonth()) {
            case 0:
                this.month = "January";
                break;
            case 1:
                this.month = "February";
                break;
            case 2:
                this.month = "March";
                break;
            case 3:
                this.month = "April";
                break;
            case 4:
                this.month = "May";
                break;
            case 5:
                this.month = "June";
                break;
            case 6:
                this.month = "July";
                break;
            case 7:
                this.month = "August";
                break;
            case 8: 
                this.month = "September"
                break;
            case 9:
                this.month = "October"
                break;
            case 10:
                this.month = "November"
                break;
            case 11:
                this.month = "December"
            }

            this.myDate = this.day + ", " + day + " " + this.month + " " + " " + year;
          }
      }
}
</script>