<template>
  <div class="home-container">
    <navbar title="Change Password"></navbar>
    <div
      style="position: relative; width: 100svw; max-width: 600px; top: 10svh"
    >
      <div style="width: 90%; margin: 0 auto">
        <div class="pw-input-group">
          <label for="email">Kata sandi lama</label>
          <input
            name="password"
            type="password"
            class="input"
            id="old-password"
            @focus="oldselected = true"
            @blur="oldselected = false"
            v-model="old_password"
          />
          <div class="show-password-icon" @click="oldShow">
            <i
              v-show="!old"
              class="fa-solid fa-eye-slash"
              :class="{ selected: oldselected }"
            ></i>
            <i
              v-show="old"
              class="fa-solid fa-eye"
              :class="{ selected: oldselected }"
            ></i>
          </div>
          <span
            style="
              position: absolute;
              bottom: -20px;
              margin-top: -15px;
              color: var(--red);
            "
            >{{ error_old }}</span
          >
        </div>

        <div class="pw-input-group" style="margin-top: 20px">
          <label for="id">Kata sandi baru</label>
          <input
            name="id"
            type="password"
            class="input"
            id="new-password"
            @focus="npasSelected = true"
            @blur="npasSelected = false"
            v-model="new_password"
          />
          <div class="show-password-icon" @click="newShow">
            <i
              v-show="!npas"
              class="fa-solid fa-eye-slash"
              :class="{ selected: npasSelected }"
            ></i>
            <i
              v-show="npas"
              class="fa-solid fa-eye"
              :class="{ selected: npasSelected }"
            ></i>
          </div>
          <span
            style="
              position: absolute;
              bottom: -20px;
              margin-top: -15px;
              color: var(--red);
            "
            >{{ error_new }}</span
          >
        </div>

        <div class="pw-input-group" style="margin-top: 20px">
          <label for="nama">konfirmasi kata sandi baru</label>
          <input
            name="nama"
            type="password"
            class="input"
            id="conf-password"
            @focus="confSelected = true"
            @blur="confSelected = false"
            v-model="conf_password"
          />
          <div class="show-password-icon" @click="confShow">
            <i
              v-show="!conf"
              class="fa-solid fa-eye-slash"
              :class="{ selected: confSelected }"
            ></i>
            <i
              v-show="conf"
              class="fa-solid fa-eye"
              :class="{ selected: confSelected }"
            ></i>
          </div>
          <span
            style="
              position: absolute;
              bottom: -20px;
              margin-top: -15px;
              color: var(--red);
            "
            >{{ error_conf }}</span
          >
        </div>
      </div>

      <br />
      <span
        style="color: var(--dark); font-weight: 600; margin-left: 5%; top: 50px"
        >Password must contains :</span
      >
      <div
        style="
          width: 90%;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          font-weight: 400;
          color: var(--gray);
        "
      >
        <span
          style="width: 50%"
          :class="{ bluecolor: uppercase >= 0, graycolor: uppercase === 0 }"
          >One Uppercase</span
        >
        <span
          style="width: 50%"
          :class="{ bluecolor: lowercase > 0, graycolor: lowercase === 0 }"
          >One Lowercase</span
        >
        <span
          style="width: 50%"
          :class="{ bluecolor: numeric > 0, graycolor: numeric === 0 }"
          >One Number</span
        >
        <span
          style="width: 50%"
          :class="{ bluecolor: special > 0, graycolor: special === 0 }"
          >One Special Character</span
        >
        <span
          style="width: 100%"
          :class="{
            bluecolor: new_password.length >= 8,
            graycolor: new_password.length === 0,
          }"
          >Minimum 8 character length</span
        >
      </div>

      <button class="save-btn" @click="submitPassword">Save</button>
    </div>
  </div>

  <alert
    v-if="showAlert"
    :message="message"
    :status="status"
    :type="types"
    @clicked="onClosedAlert"
  >
  </alert>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Alert from "@/components/Alert.vue";
import axios from "axios";
export default {
  name: "ChangePasswordView",
  components: { Navbar, Alert },
  data() {
    return {
      old: false,
      oldselected: false,
      npas: false,
      npasSelected: false,
      conf: false,
      confSelected: false,
      old_password: null,
      new_password: "",
      conf_password: null,
      showAlert: false,
      message: null,
      status: false,
      types: null,
      error_old: null,
      error_new: null,
      error_conf: null,
      uppercase: 0,
      lowercase: 0,
      numeric: 0,
      special: 0,
    };
  },
  watch: {
    new_password: function () {
      this.numeric = 0;
      this.special = 0;
      this.uppercase = 0;
      this.lowercase = 0;
      let arrPassword;
      var character = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      // const excludeKey = [16, 17, 18, 19, 20, 27, 8, 9, 127, 24, 33, 34, 35, 36, 37, 38, 39, 40, 19, 45, 46, 123, 93]
      if (this.new_password) {
        arrPassword = this.new_password.split("");
        if (this.new_password.trim().length === 0) {
          document.getElementById("password").value = "";
          this.new_password = "";
          this.error_new = "Space not allowed!";
          setTimeout(() => {
            this.error_new = null;
          }, 2000);
          return;
        }

        arrPassword.forEach((word) => {
          if (/^\d$/.test(word)) {
            this.numeric += 1;
          } else {
            if (character.test(word)) {
              this.special += 1;
            } else {
              if (word === word.toUpperCase()) {
                this.uppercase += 1;
              }

              if (word === word.toLowerCase()) {
                this.lowercase += 1;
              }
            }
          }
        });
      }
    },
  },
  methods: {
    onClosedAlert(value) {
      this.showAlert = value;
    },
    oldShow() {
      if (this.old === false) {
        this.old = true;
        document.getElementById("old-password").type = "text";
      } else {
        this.old = false;
        document.getElementById("old-password").type = "password";
      }
    },
    newShow() {
      if (this.npas === false) {
        this.npas = true;
        document.getElementById("new-password").type = "text";
      } else {
        this.npas = false;
        document.getElementById("new-password").type = "password";
      }
    },
    confShow() {
      if (this.conf === false) {
        this.conf = true;
        document.getElementById("conf-password").type = "text";
      } else {
        this.conf = false;
        document.getElementById("conf-password").type = "password";
      }
    },
    submitPassword() {
      let counter = 0;
      if (!this.old_password) {
        counter++;
        this.error_old = "Old password field is required";
      }

      if (!this.new_password) {
        counter++;
        this.error_new = "New password field is required";
      }

      if (!this.conf_password) {
        counter++;
        this.error_conf = "Password confirmation field is required";
      }

      if (counter > 0) {
        setTimeout(() => {
          this.error_old = null;
          this.error_new = null;
          this.error_conf = null;
        }, 5000);
        return;
      }

      if (this.new_password !== this.conf_password) {
        this.message = "Password confirmation not match";
        this.status = false;
        this.showAlert = true;
        return;
      }

      const data = {
        nik: this.$store.getters.GET_AUTH_INFO.nik,
        password: this.old_password,
        new_password: this.new_password,
      };

      axios
        .post("/update_password", data, {
          headers: {
            Authorization: this.$store.getters.GET_AUTH_TOKEN,
          },
        })
        .then((res) => {
          this.message = res.data.message;
          this.status = true;
          this.types = "home";
          this.showAlert = true;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$store
              .dispatch("REFRESH")
              .then(() => {
                window.location.reload();
              })
              .catch(() => {
                this.$router.push({ name: "login" });
              });
          } else {
            this.message = err.response.data.message;
            this.status = false;
            this.showAlert = true;
            return;
          }
        });
    },
  },
};
</script>

<style>
.selected {
  color: #0c98e4;
}

.show-password-icon {
  position: absolute;
  font-size: 13pt;
  bottom: 10px;
  right: 3%;
  color: #878787;
  cursor: pointer;
}

.pw-input-group {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 auto;
}

.pw-input-group .input {
  width: 95%;
  height: 40px;
  border-radius: 5px;
  padding-left: 5%;
  font-size: 13pt;
  background-color: var(--border);
  border: none;
}

.pw-input-group .input:is(:focus, :active) {
  border: 1px solid #0c98e4;
}

.pw-input-group .input-textarea {
  width: 100%;
  border-radius: 5px;
  font-size: 13pt;
  background-color: #eaeaea;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
}
</style>