<template>
    <attendance-detail-loader v-show="loading"></attendance-detail-loader>
    <div v-show="!loading" class="home-container">
        <navbar title="Attendance Detail"></navbar>
        <div class="body-page">
              <div class="map-container-detail">
                    <!-- <l-map ref="map" v-model:zoom="zoom" :center="[currPos.lat, currPos.lng]" :options="{zoomControl: false, attributionControl: false}">
                        <l-tile-layer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" layer-type="base" name="OpenStreetMap"></l-tile-layer>
                        <l-circle-marker :lat-lng="[currPos.lat, currPos.lng]" :radius="7" 
                        :color="'red'" :stroke="false" :fill="true" :fillColor="'#d40000'" :fillOpacity="1" />
                        <l-circle-marker :lat-lng="[currPos.lat, currPos.lng]" :radius="14" 
                        :color="'red'" :stroke="false" :fill="true" :fillColor="'#d40000'" :fillOpacity="0.4" />
                        <l-circle :lat-lng="[rad.lat, rad.lng]" :radius="50" :stroke="false" 
                        :color="'#0084d1'" :fill="true" :fillColor="'#0084d1'" :fillOpacity="0.5"
                        v-for="rad in location" :key="rad.id"></l-circle>
                    </l-map> -->
              </div>
              <div class="clock-detail">
                  <div class="handle"></div>
                  <div class="detail-item">
                        <span>Foto</span>
                        <img style="width: 50%;height: 200px;object-fit: cover" v-if="!loading" id="attendanceimg" alt="">
                  </div>
                  <div class="detail-item">
                        <span>Tanggal</span>
                        <span class="desc">{{ dates(details.attendance_date) }} {{ details.attendance_time }}</span>
                  </div>
                  <div class="detail-item">
                        <span>Tipe</span>
                        <span class="desc">{{ getClocking(details.clocking) }}</span>
                  </div>
                  <div class="detail-item">
                        <span>Lokasi</span>
                        <span class="desc">{{ details.lok_nm }}</span>
                  </div>
                  <div class="detail-item">
                        <span>Keterangan</span>
                        <span class="desc">-</span>
                  </div>
              </div>
        </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import Navbar from '@/components/Navbar.vue';
  import { computed } from 'vue';
  import { useGeolocation } from '@/useGeolocation';
  import "leaflet/dist/leaflet.css";
  import L from 'leaflet'
  import AttendanceDetailLoader from '@/components/Loader/AttendanceDetailLoader.vue';
  import { DateTime } from 'luxon';
//   import { LMap, LTileLayer, LCircle, LCircleMarker } from "@vue-leaflet/vue-leaflet";
  
  export default {
      name: 'AttendanceDetailView',
      props: {
        title: String,
      },
      components: {
        // LMap, LTileLayer, LCircle, LCircleMarker,
        Navbar, AttendanceDetailLoader,
      },
      data(){
      return {
              times: '00:00',
              zoom: 17,
              crntpos: null,
              loading: true,
              details: {},
              day: 0,
              month: 0,
              images: null,
          }
      },
      setup(){
        const { coords } = useGeolocation();
        const currPos = computed(() => ({
          lat: coords.value.latitude,
          lng: coords.value.longitude,
        }));
        return{ currPos }
      },
      beforeRouteLeave(){this.removeMaps()},
      beforeRouteUpdate(){this.removeMaps()},
      created(){
          this.getAttendanceDetail();
          this.createMaps();
      },
      methods: {
        getClocking(clock){
            if(clock) return "Clock In"
            else return "Clock Out"
        },
        async getImage(filename){
          await fetch(this.uri + '/images/attendance/' + filename, {
            method: 'GET',
            headers: { 
                'Authorization' : this.$store.getters.GET_AUTH_TOKEN
            }
          }).then((res) => {
                return res.blob()
          }).then((blob) => {
                let url = URL.createObjectURL(blob)
                document.getElementById('attendanceimg').src = url
          })
        },
        async getAttendanceDetail(){
          try{ 
            const { data } = await axios.get('/attendance/detail/' + this.$route.params.id, {
                headers: {
                  'Authorization': this.$store.getters.GET_AUTH_TOKEN
                }
            })

            this.details = data.data
            this.getImage(this.details.photo)
            this.isInRange();
          } catch(e){
            if(e.response.status == 401){
                this.$store.dispatch("REFRESH")
                .then(() => {
                    window.location.reload()
                }).catch(() => {
                    this.$router.push({name: 'login'})
                })
            } else if(e.response.status == 406) {
                this.$store.dispatch("LOGOUT")
                .then(() => {
                    this.$router.push({ path : '/'});
                }).catch(() => {
                    this.$router.push({ path : '/'});
                });
            }
          }
        },
        removeMaps(){
            const newMap = document.getElementById("map");
            newMap.remove();
        },
        createMaps(){
            const newMap = document.createElement("div");
            newMap.setAttribute("id", "map");
            const parentDiv = document.getElementById("app");
            document.body.insertBefore(newMap, parentDiv);
        },
        isInRange(){
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.currentlat = position.coords.latitude;
                    this.currentlng = position.coords.longitude;
                    // this.accuracy = position.coords.accuracy;
                    // var map = L.map('map').setView([this.currentlat, this.currentlng], 16);
                    var map = L.map('map', {
                        center: [this.details.latitude, this.details.longitude],
                        zoom: 17,
                        zoomControl: false,
                    });

                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        maxZoom: 19, 
                        attribution: '© OpenStreetMap'
                    }).addTo(map);

                    new L.circle([parseFloat(this.details.latitude), parseFloat(this.details.longitude)], { 
                        radius: 7, 
                        color: 'red',
                        stroke: false,
                        fill: true,
                        fillColor: '#b22a2e',
                        fillOpacity: 0.8,
                    }).addTo(map);

                    new L.circle([parseFloat(this.details.latitude), parseFloat(this.details.longitude)], { 
                        radius: 14, 
                        color: 'red',
                        stroke: false,
                        fill: true,
                        fillColor: '#b22a2e',
                        fillOpacity: 0.4,
                    }).addTo(map);
                },
                (error) => {alert(`Error getting location: ${error.message}`)},
                {maximumAge:10000, timeout:5000, enableHighAccuracy: true},
              );
              this.loading = false;
            } else {alert("Geolocation is not supported in this browser.")}
        },
        dates(myDate){
            const parsedDate = DateTime.fromFormat(String(myDate), 'M/d/yyyy');
            var months = parsedDate.month
            var day = parsedDate.day;
            var year = parsedDate.year;
            // var months = parsedDate.month

            switch (months) {
            case 1:
                this.month = "January";
                break;
            case 2:
                this.month = "February";
                break;
            case 3:
                this.month = "March";
                break;
            case 4:
                this.month = "April";
                break;
            case 5:
                this.month = "May";
                break;
            case 6:
                this.month = "June";
                break;
            case 7:
                this.month = "July";
                break;
            case 8:
                this.month = "August";
                break;
            case 9: 
                this.month = "September"
                break;
            case 10:
                this.month = "October"
                break;
            case 11:
                this.month = "November"
                break;
            case 12:
                this.month = "December"
            }

            return day + " " + this.month + " " + " " + year;
        },
      }
  }
  </script>
  
  <style>
  
  </style>