<template>
    <inbox-loader v-show="loading"></inbox-loader>
    <div v-show="!loading" class="home-container">
      <navbar title="Inbox"></navbar>
      <div class="body-page">
          <div class="body-section mt-60" style="overflow: hidden;">
              <div class="title">
                  <h3>Periode</h3>
                  <select 
                    class="month-pick"
                    v-model="period" 
                    @change="getInbox">
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                  </select>
              </div>
          </div>
      </div>

      <div style="display: flex;flex-direction: column;gap: 10px;width: 94%;margin: 0 auto;">
            <div class="leave-approve">
                <div class="leave-card" v-for="inbx in userinbox.inbox" :key="inbx.id">
                    <span class="leave-title">{{ inbx.message }}</span>
                    <span class="leave-employee">{{ userinbox.user }}</span>
                    <span class="leave-date">{{ new Date(inbx.createdAt).toDateString() }}, {{ new Date(inbx.createdAt).toLocaleTimeString() }}</span>
                    <span class="leave-period"></span>
                    <div :class="{'leave-status-success': inbx.status === 'Success',
                    'leave-status-success': inbx.status === 'Approved',
                    'leave-status-pending': inbx.status === 'Pending',
                    'leave-status-fail': inbx.status === 'Rejected'}"
                    style="text-align: center;width: 80px;">
                        {{ inbx.status }}
                    </div>
                    <router-link 
                        v-if="inbx.redirect && inbx.status === 'Pending'" 
                        :to="{name: inbx.redirect, params: {ibxid: inbx.id, id: inbx.itemid}}" 
                        class="leave-detail">
                        View
                    </router-link>   
                    
                    <router-link 
                        v-if="inbx.status === 'Approved'" 
                        :to="{name: 'leave-view', params: {name: inbx.itemid}}" 
                        class="leave-detail">
                        View
                    </router-link>  
                </div>
            </div>

            <div class="mb-50"></div>
      </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import InboxLoader from '@/components/Loader/InboxLoader.vue';
import axios from 'axios';

export default {
    name: 'InboxView',
    components: { Navbar, InboxLoader },
    data(){
        return {
            loading: true,
            period: null,
            userinbox: [],
            leave_approval: [
                {
                    id: 'LV20230829X12',
                    type: 'Paid Leave',
                    date: '29-08-2023',
                    period: '1 Days leave',
                    employee: 'John Doe',
                }
            ]
        }
    },
    created(){
        this.period = new Date().getMonth() + 1
        this.getInbox();
    },
    methods: {
        async getInbox(){
            try {
                const  { data } = await axios.get('/inbox', {
                    headers: {
                        'Authorization' : this.$store.getters.GET_AUTH_TOKEN,
                        'Period': this.period
                    }
                })

                this.userinbox = data.data
                this.loading = false
            } catch(e){
                if(e.response.status == 401){
                    this.$store.dispatch("REFRESH")
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(() => {
                        this.$router.push({name: 'login'})
                    })
                } else if(e.response.status == 406) {
                    this.$store.dispatch("LOGOUT")
                    .then(() => {
                        this.$router.push({ path : '/'});
                    }).catch(() => {
                        this.$router.push({ path : '/'});
                    });
                } 
            }
        }
    }
}
</script>

<style>
.leave-approve{
    position: relative;
    width: 100%;
    top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.leave-approve .leave-card{
    position: relative;
    top: 0;
    width: 92%;
    height: 80px;
    border:  1.5px solid var(--border);
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 15px
}

.leave-approve .leave-card .leave-title{
    font-size: 12pt;
    font-weight: bold;
    color: var(--bluecolor);
}

.leave-approve .leave-card .leave-employee{
    margin-top: 10px;
    font-size: 12pt;
    font-weight: 400;
    color: #474747;
}

.leave-approve .leave-card .leave-date{
    margin-top: 3px;
    font-size: 12pt;
    font-weight: bold;
    color: #474747;
}

.leave-approve .leave-card .leave-period{
    margin-top: 3px;
    font-size: 10pt;
    color: #e0e0e0;
    font-weight: 400;
}

.leave-approve .leave-card .leave-status-success{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 120px;
    height: 25px;
    border-radius: 10px;
    background: #00e28f;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.leave-approve .leave-card .leave-status-fail{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #b50000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.leave-approve .leave-card .leave-status-pending{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 80px;
    height: 25px;
    border-radius: 10px;
    background: #ffed2b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff
}

.leave-approve .leave-card .leave-detail{
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: #838383;
    font-size: 10pt;
}
</style>