<template>
    <div class="top-page-nav">
        <div class="navigation-section">
            <div class="navigation-name">
                <div style="width: 20%;cursor: pointer;">
                    <a @click="this.$router.push('/')"><i class="fa-solid fa-arrow-left"></i></a>
                </div>
                <div style="width: 65%;display: flex;flex-direction: row ;justify-content: center;">
                    <span>{{ title }}</span>
                    <span v-show="!title">{{ times }}</span>
                </div>
                <div v-show="!title" style="width: 15%;cursor: pointer;">
                    <i class="fa-solid fa-rotate-right" @click="reloadPage"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavbarVue', 
    props: { title: String },
    data(){
        return{
            times: '00:00'
        }
    },
    created(){
        setInterval(() => {this.time()}, 1000);
    },
    methods: {
        routeBack(){ this.$router.go(-1)},
        reloadPage(){ window.location.href = '/' + this.$route.name },
        time() {
              var d = new Date();
              var s = d.getSeconds();
              var m = d.getMinutes();
              var h = d.getHours();
              this.times = ("0" + h).substr(-2) + ":" + ("0" + m).substr(-2) + ":" + ("0" + s).substr(-2);
        },
    }
}
</script>

<style>

</style>