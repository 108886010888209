<template>
    <div class="forgot-loader">        
        <div class="forgot-form">
            <div class="shimmer-wrapper" style="width: 100%;">
                <div style="display: flex; flex-direction: columns;gap: 10px" class="mt-40">
                    <div style="width: 25px;height: 25px;" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
                    <div style="margin-top: 9px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
                </div>
                
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate mt-40 h-50"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate mt-30 h-50"></div>
                <div style="width:100px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-20 h-50"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ForgotLoader',
}
</script>

<style>
.forgot-loader{
    position: relative;
    top: 0;
    left: 0;
    width: 100svw;
    height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 20px;
    z-index: 1000;
    overflow: hidden;
}

.forgot-loader .forgot-form{
    position: relative;
    left: -1%;
    width: 86%;
    max-width: 400px;    
    height: 305px;
    border-radius: 5px;
    border: 1.5px solid #e0e0e0;
    margin: 0 auto;
    margin-left: -10px;
    padding: 15px;
    z-index: 2;
    background: #fff;
    box-shadow: 0 3px 5px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
</style>