<template>
    <reset-loader v-show="loading"></reset-loader>
    <div v-show="!loading" class="login-container">
        <img class="login-bg" id="login-bg" :src="selectedImage" alt="">
        <div class="login-form">
            <p class="login-form-title" style="margin-top: 0px;">Change Password</p>

            <label style="margin-top: -15px;">
                <input required="" placeholder="" type="password" class="input" 
                       v-model="password" id="password" @keypress.enter="reset" 
                       @keypress.space="deleteSpace">
                <span id="label-password">New Password</span>
                <div class="show-password" @click="showPass" id="show-password">
                    <i v-show="!showPassword" class="fa-solid fa-eye-slash" id="password-eyes"></i>
                    <i v-show="showPassword" class="fa-solid fa-eye"></i>
                </div>
                <div class="error-message">{{ errorPassword }}</div>
            </label> 
                
            <label>
                <input required="" placeholder="" type="password" class="input" 
                       v-model="conf_password" id="confirmation" @keypress.enter="reset">
                <span id="label-confirmation">Confirm Password</span>
                <div class="show-password" @click="showConf" id="show-confirmation">
                    <i v-if="!showConfirmation" class="fa-solid fa-eye-slash" id="confirmation-eyes"></i>
                    <i v-if="showConfirmation" class="fa-solid fa-eye"></i>
                </div>
                <div class="error-message">{{ errorConfirmation }}</div>
            </label>

            <span style="color: var(--dark);font-weight: 600;">Password must contains :</span>
            <div style="position:relative;width:100%;display: flex;flex-direction: row;flex-wrap: wrap;font-weight: 400;color: var(--gray);">
              <span style="width: 50%;" :class="{bluecolor : uppercase >= 0, graycolor : uppercase === 0}">One Uppercase</span>
              <span style="width: 50%;" :class="{bluecolor : lowercase > 0, graycolor : lowercase === 0}">One Lowercase</span>
              <span style="width: 50%;" :class="{bluecolor : numeric > 0, graycolor : numeric === 0}">One Number</span>
              <span style="width: 50%;" :class="{bluecolor : special > 0, graycolor : special === 0}">One Special Character</span>
              <span style="width: 100%;" :class="{bluecolor : password.length >= 8, graycolor : password.length === 0}">Minimum 8 character length</span>
            </div>

            <button class="login-form-submit" @click="reset" style="margin-bottom: 0px;">
              <span v-if="!is_proccess">Submit</span>

              <div v-if="is_proccess" class="spinner center">
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
                  <div class="spinner-blade"></div>
              </div>
            </button>

            <span class="login-bottom-text">
              <span class="copyright">©</span> 
                  ESS MELAWAI GROUP
              <span class="version">v1.0</span>
            </span>
        </div>
    </div>
    
    <alert v-if="showAlert" 
          :message="message" 
          :status="status" 
          :type="types"
          :action="action"
          :data="data"
          @clicked="onClosedAlert">
    </alert>
</template>

<script>
import ResetLoader from '@/components/Loader/ResetLoader.vue';
import Alert from '@/components/Alert.vue';
import axios from 'axios';

export default {
    name: 'ResetView',
    components: {
      ResetLoader,
      Alert,
    },
    data(){
      return {
          action: null,
          data: null,
          password: '',
          conf_password: '',
          errorPassword: null,
          errorConfirmation: null,
          loading: true,
          is_proccess: false,
          showPassword: false,
          showConfirmation: false,
          showAlert: false,
          message: null,
          status: false,
          types: null,
          isChecking: false,
          uppercase: 0,
          lowercase: 0,
          numeric: 0,
          special: 0,
          images: [
            '/assets/images/bg-1.png',
            // '/assets/images/tamani.png',
            // '/assets/images/sti.png',
            // '/assets/images/movenpick.png',
            // '/assets/images/rmk.png',
            // '/assets/images/expresso.png',
            // '/assets/images/visicare.png',
            // '/assets/images/iris.png',
          ],
          selectedImage: null,
      }
    },
    watch: {
        selectedImage: function() {
          var root = document.documentElement;
          switch (this.selectedImage) {
             // case "/assets/images/bg-1.png":
            //     root.style.setProperty("--logincolor", "#33601d");
            //     root.style.setProperty("--loginhover", "#172e0c");
            //     localStorage.setItem("basecolor","#33601d");
            //     localStorage.setItem("basehover", "#172e0c");
            //     break;
            case "/assets/images/bg-1.png":
                root.style.setProperty("--logincolor", "#b22a2e");
                root.style.setProperty("--loginhover", "#7a181b");
                localStorage.setItem("basecolor","#b22a2e");
                localStorage.setItem("basehover", "#7a181b");
                break;
            // case "/assets/images/3.png":
            //     root.style.setProperty("--logincolor", "#700921");
            //     root.style.setProperty("--loginhover", "#470212");
            //     localStorage.setItem("basecolor","#700921");
            //     localStorage.setItem("basehover", "#470212");
            //     break;
            // case "/assets/images/4.png":
            //     root.style.setProperty("--logincolor", "#9f8c50");
            //     root.style.setProperty("--loginhover", "#69592a");  
            //     localStorage.setItem("basecolor","#9f8c50");
            //     localStorage.setItem("basehover", "#69592a"); 
            //     break;
            // case "/assets/images/5.png":
            //     root.style.setProperty("--logincolor", "#732c3d");
            //     root.style.setProperty("--loginhover", "#541827");
            //     localStorage.setItem("basecolor","#732c3d");
            //     localStorage.setItem("basehover", "#541827");
            //     document.getElementById("login-bg").style.marginTop = "3svh"
            //     break;
            // case "/assets/images/6.png":
            //     root.style.setProperty("--logincolor", "#474747");
            //     root.style.setProperty("--loginhover", "#292828");
            //     localStorage.setItem("basecolor","#474747");
            //     localStorage.setItem("basehover", "#292828");
            //     document.getElementById("login-bg").style.marginTop = "-5svh"
            //     break;
            // case "/assets/images/7.png":
            //     root.style.setProperty("--logincolor", "#00bfe0");
            //     root.style.setProperty("--loginhover", "#048ca4");
            //     localStorage.setItem("basecolor","#00bfe0");
            //     localStorage.setItem("basehover", "#048ca4");
            //     document.getElementById("login-bg").style.marginTop = "-9svh"
            //     break;
            // case "/assets/images/8.png":
            //     root.style.setProperty("--logincolor", "#0172c0");
            //     root.style.setProperty("--loginhover", "#024370");
            //     localStorage.setItem("basecolor","#0172c0");
            //     localStorage.setItem("basehover", "#024370");
            //     document.getElementById("login-bg").style.marginTop = "-10svh"
            //     document.getElementById("login-bg").style.width = "50%"
            //     document.getElementById("login-bg").style.maxWidth = "220px"
            //     break;
            }
        },
        password: function() {
            this.numeric = 0;
            this.special = 0;
            this.uppercase = 0;
            this.lowercase = 0;
            let arrPassword;
            this.checkFocus('password', this.password);
            var character = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
            // const excludeKey = [16, 17, 18, 19, 20, 27, 8, 9, 127, 24, 33, 34, 35, 36, 37, 38, 39, 40, 19, 45, 46, 123, 93]        
            if (this.password) {
                arrPassword = this.password.split("");
                if(this.password.trim().length === 0){
                  document.getElementById('password').value = ''
                  this.password = ''
                  this.errorPassword = 'Space not allowed!'
                  setTimeout(() => {this.errorPassword = null}, 2000)
                  return;
                }

                arrPassword.forEach((word) => {
                if (/^\d$/.test(word)){
                    this.numeric += 1;
                } else {
                      if( character.test(word) ){
                          this.special += 1;
                      } else {
                          if (word === word.toUpperCase()) {
                            this.uppercase += 1;
                          }
                          
                          if (word === word.toLowerCase()){
                              this.lowercase += 1;
                          }
                      }
                    }
                })
            } 
        },
        conf_password: function() {
            this.checkFocus('confirmation', this.conf_password);
        }
    },
    created() {
        this.verifyToken()
        this.selectedImage = this.randomItem(this.images)
    },
    methods: {
        deleteSpace(){
            document.getElementById('password').value = ''
            this.password = ''
            this.errorPassword = 'Space not allowed!'

            setTimeout(() => {
              this.errorPassword = null
            }, 2000)

            return;
        },
        async verifyToken(){
            try {
                await axios.get('/verify/' + this.$route.params.token)
                this.loading = false
            } catch(e){
                this.$router.push({name: 'login'});
            }
        },
        showPass(){
            const eyes = document.getElementById("show-password");
            if(this.showPassword === false) {
                this.showPassword = true;
                document.getElementById("password").type = 'text';
                eyes.style.color = "var(--logincolor)"
            } else {
                this.showPassword = false;
                document.getElementById("password").type = 'password';
                eyes.style.color = "var(--gray)"
            }
      },
      showConf(){
            const eyes = document.getElementById("show-confirmation");
            if(this.showConfirmation === false) {
                this.showCofirmation = true;
                document.getElementById("confirmation").type = 'text';
                eyes.style.color = "var(--logincolor)"
            } else {
                this.showConfirmation = false;
                document.getElementById("confirmation").type = 'password';
                eyes.style.color = "var(--gray)"
            }
      },
      checkFocus(id, data){
        if(data !== '') {
          document.getElementById(id).focus();
          const label = document.getElementById("label-" + id);
          label.style.fontFamily = 'Arial, Helvetica, sans-serif';
          label.style.top = '30px';
          label.style.fontSize = '8pt';
          label.style.fontWeight = '600';
        } else { 
          document.getElementById(id).blur()
          const label = document.getElementById("label-" + id);
          label.style.fontFamily = 'Arial, Helvetica, sans-serif';
          label.style.top = '15px';
          label.style.fontSize = '11pt';
          label.style.fontWeight = '400';
        }
      },
      randomItem (items) {
        return items[Math.floor(Math.random()*items.length)];
      },
      async reset(){
        if(!this.password) this.errorPassword = "Password field harus diisi";
        if(!this.conf_password) this.errorConfirmation = "Password confirmation harus diisi";
        if(!this.password || !this.conf_password){
            setTimeout(() => {
                this.errorPassword = null;
                this.errorConfirmation = null;
            }, 5000);
            
            return;
        } 

        if(this.password !== this.conf_password) {
            this.message = "Password confirmation did not match"
            this.status = false
            this.showAlert = true
            return;
        }

        this.is_proccess = true

        const data = {
          password: this.password,
          token: this.$route.params.token,
        }

        await axios.post('/reset', data)
        .then((res) => {
            this.is_proccess = false
            this.message = res.data.message
            this.status = true
            this.types = 'login'
            this.showAlert = true
        })
        .catch((err) => {
            this.is_proccess = false
            this.message = err.response.data.message
            this.status = false
            this.showAlert = true
            return;
        })
      },
      onClosedAlert(value){this.showAlert = value},
    },    
}
</script>

<style>
.bluecolor{
  color: var(--bluecolor);
}

.graycolor{
  color: var(--gray);
}

.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: 10px;
}

.spinner.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #fff;
  }

  100% {
    background-color: transparent;
  }
}
</style>