import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/main.css';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App);

axios.defaults.baseURL = 'https://api.saritirta-group.com/api/v1';
app.config.globalProperties.uri = 'https://api.saritirta-group.com';

// axios.defaults.baseURL = 'http://139.0.5.101/api/v1';
// app.config.globalProperties.uri = 'http://139.0.5.101';

app.component('VueDatePicker', VueDatePicker);
app.config.devtools = false;
app.use(VueCookies)
app.use(store).use(router).mount('#app')
